import constate from "constate"
import { useEffect, useState } from "react"
import useHelpMenu from "./useHelpMenu"

const [JobPostEditorModalProvider, useJobPostEditorModal] = constate(() => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [post, setPost] = useState<string | undefined>(undefined)
    const [team, setTeam] = useState<string | undefined>(undefined)
    const { maybeSetIsVisible } = useHelpMenu()

    useEffect(() => {
        // hides Intercom when modal is visible
        maybeSetIsVisible(!isModalOpen)
    }, [isModalOpen, maybeSetIsVisible])

    const showModal = () => setModalOpen(true)
    const hideModal = () => setModalOpen(false)

    const showPostModal = (post: string, teamUuid?: string) => {
        setPost(post)
        setTeam(teamUuid)
        setModalOpen(true)
    }

    const showCreatePostModal = (teamUuid?: string) => {
        setPost(undefined)
        setTeam(teamUuid)
        setModalOpen(true)
    }

    return {
        isModalOpen,
        showModal,
        hideModal,
        post,
        setPost,
        team,
        setTeam,
        showPostModal,
        showCreatePostModal,
    }
})

export { JobPostEditorModalProvider }

export default useJobPostEditorModal

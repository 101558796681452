import { gql, useQuery } from "@apollo/client"
import { GetJobPostMetadata } from "__generated__/GetJobPostMetadata"

const GET_JOB_METADATA_QUERY = gql`
    query GetJobPostMetadata {
        experience {
            id
            name
            slug
        }
        workTypes {
            id
            name
            slug
        }
        supportedCurrencies {
            label
            value
        }
    }
`

export const useMetadataQuery = () =>
    useQuery<GetJobPostMetadata>(GET_JOB_METADATA_QUERY)

import { Banner, BaseModal, Box, Text, theme } from "@pallet-hq/hegel"
import { ThemeProvider } from "@emotion/react"
import { Form } from "formik"
import { VerticallyCenteredBox } from "components/atoms"
import SplitDivider from "components/SplitDivider"
import { useApplylistQuery } from "features/job-posts/editor/hooks/useApplylistQuery"
import useJobPostEditorModal from "state/useJobPostEditorModal"
import PostFormProvider from "./PostFormProvider"
import JobScraperInput from "./JobScraperInput"
import JobPostForm from "./JobPostForm"
import ActionRow from "./ActionRow"
import { FiX } from "react-icons/fi"
import { useEffect } from "react"
import useHelpMenu from "state/useHelpMenu"

export type PostEditorModalProps = {
    afterSubmit?: (postUuid: string, postTitle?: string) => void
}

const PostEditorModal = (props: PostEditorModalProps) => {
    const { post, isModalOpen, hideModal } = useJobPostEditorModal()
    const { data } = useApplylistQuery()
    const { setIsVisible } = useHelpMenu()

    const handleAfterSubmit = (postUuid: string, postTitle?: string) => {
        !!props.afterSubmit && props.afterSubmit(postUuid, postTitle)
        hideModal()
        return
    }

    useEffect(() => {
        if (isModalOpen) setIsVisible(false)
    }, [isModalOpen, setIsVisible])

    return (
        <ThemeProvider
            theme={{
                ...theme,
                colors: {
                    ...theme.colors,
                    primary:
                        data?.applylist?.primaryColor || theme.colors.primary,
                },
            }}
        >
            <BaseModal
                style={{
                    overlay: {
                        backgroundColor: theme.colors.black4,
                        zIndex: 2,
                    },
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                        height: "calc(100% - 24px)",
                        maxWidth: "760px",
                        maxHeight: "100%",
                        borderRadius: "8px",
                        boxShadow:
                            "0px 1px 6px rgba(0, 0, 0, 0.16), 0px 6px 20px rgba(0, 0, 0, 0.2)",
                    },
                }}
                isOpen={isModalOpen}
                hideModal={hideModal}
            >
                <VerticallyCenteredBox m="small">
                    <Text variant="headlineSmall">
                        {post ? "Edit job post" : "Add a job post"}
                    </Text>
                    <VerticallyCenteredBox
                        ml="auto"
                        onClick={hideModal}
                        style={{ cursor: "pointer" }}
                    >
                        <FiX size={18} stroke={theme.colors.gray600} />
                    </VerticallyCenteredBox>
                </VerticallyCenteredBox>
                <PostFormProvider
                    afterSubmit={handleAfterSubmit}
                    postUuid={post}
                >
                    <>
                        <VerticallyCenteredBox
                            flexDirection="column"
                            width="auto"
                            height={[
                                "100%",
                                `calc(100% - 132px)`,
                                `calc(100% - 132px)`,
                            ]}
                            overflow="auto"
                        >
                            {data?.applylist?.canEdit && (
                                <Banner
                                    label="Post a job to your pallet"
                                    fullWidth
                                />
                            )}
                            <Box
                                width={["100%", "750px"]}
                                p="12px"
                                style={{ boxSizing: "border-box" }}
                            >
                                <Form>
                                    {!post && (
                                        <>
                                            <JobScraperInput />
                                            <SplitDivider label="or create from scratch" />
                                        </>
                                    )}
                                    <JobPostForm />
                                </Form>
                            </Box>
                        </VerticallyCenteredBox>
                        <ActionRow />
                    </>
                </PostFormProvider>
            </BaseModal>
        </ThemeProvider>
    )
}

export default PostEditorModal

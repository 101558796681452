import { gql, useQuery } from "@apollo/client"
import { EDIT_JOB_POST_FRAGMENT } from "../fragments"
import { GetJobPost } from "__generated__/GetJobPost"

const GET_JOB_POST_QUERY = gql`
    query GetJobPost($uuid: UUID!) {
        post(uuid: $uuid) {
            id
            ...EditJobPost_post
        }
    }
    ${EDIT_JOB_POST_FRAGMENT}
`

export const usePostQuery = (postUuid?: string) => {
    return useQuery<GetJobPost>(GET_JOB_POST_QUERY, {
        variables: { uuid: postUuid },
        skip: !postUuid,
    })
}

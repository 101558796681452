import styled from "@emotion/styled"
import { Box, KeyboardButton, mq, theme } from "@pallet-hq/hegel"
import { useFormikContext } from "formik"
import { useHotkeys } from "react-hotkeys-hook"
import { VerticallyCenteredBox } from "components/atoms"
import useJobPostEditorModal from "state/useJobPostEditorModal"
import { isMacintosh } from "utils/userAgent"
import mixpanel from "mixpanel-browser"
import { BUTTON_TYPES, TRACK_EVENTS } from "constants/events"

const ActionRow = () => {
    const { post, hideModal } = useJobPostEditorModal()
    const modifierKey = isMacintosh() ? "⌘" : "Ctrl"
    const { handleSubmit, isSubmitting, resetForm } = useFormikContext()
    const cancelAction = () => {
        resetForm()
        hideModal()
    }

    const handleAndTrackSubmit = () => {
        handleSubmit()
        mixpanel.track(TRACK_EVENTS.BUTTON_CLICKED, {
            type: BUTTON_TYPES.CREATE_JOB,
        })
    }

    useHotkeys("ctrl+enter, cmd+enter", () => handleAndTrackSubmit(), {
        enableOnTags: ["INPUT", "TEXTAREA"],
    })

    useHotkeys("esc", cancelAction)

    return (
        <Wrapper>
            <VerticallyCenteredBox
                gap={theme.space.xsmall}
                flexDirection={["column-reverse", "row"]}
                width="100%"
            >
                <KeyboardButton
                    type="reset"
                    shortcut="Esc"
                    ml={["0px", "auto", "auto"]}
                    size="small"
                    variant="secondary"
                    width={["100%", "auto", "auto"]}
                    disabled={isSubmitting}
                    onClick={() => {
                        resetForm()
                        hideModal()
                    }}
                >
                    Cancel
                </KeyboardButton>
                <KeyboardButton
                    type="submit"
                    shortcut={`${modifierKey} · Enter`}
                    size="small"
                    variant="primary"
                    width={["100%", "auto", "auto"]}
                    loading={isSubmitting}
                    onClick={handleAndTrackSubmit}
                >
                    {post ? "Save changes" : "Post job"}
                </KeyboardButton>
            </VerticallyCenteredBox>
        </Wrapper>
    )
}

export default ActionRow

const Wrapper = styled(Box)`
    display: flex;
    border-top: 1px solid ${theme.colors.gray300};
    margin-top: auto;
    z-index: 1;
    ${mq({ padding: [theme.space.small] })};
`

import styled from "@emotion/styled"
import {
    Box,
    CheckboxField,
    CurrencyInputField,
    Divider,
    InputWithCheckboxContainer,
    mq,
    MultiSelect,
    SingleSelect,
    TextInputField,
    theme,
    ui,
} from "@pallet-hq/hegel"
import { ATSJobSelect } from "@pallet/components/inputs/ATSJobSelect"
import RichTextInput from "components/inputs/RichTextInput"
import CompanySelect from "components/inputs/Select/CompanySelect"
import JobTypeSelect from "components/inputs/Select/JobTypeSelect"
import LocationSelect from "components/inputs/Select/LocationSelect"
import { getSelectOptions } from "components/inputs/Select/utils"
import { useMetadataQuery } from "features/job-posts/editor/hooks/useMetadataQuery"
import useJobPostEditorModal from "state/useJobPostEditorModal"
import useAuthContext from "state/useAuthContext"
import useFeatureFlagsContext from "state/useFeatureFlags"

const JobPostForm = () => {
    const { team } = useJobPostEditorModal()
    const { user } = useAuthContext()
    const { data, loading } = useMetadataQuery()
    const { flagIsActive } = useFeatureFlagsContext()
    const atsEnabled = flagIsActive("ATS_INTEGRATION")

    if (loading) return null

    return (
        <Box display="flex" flexDirection="column">
            <TextInputField
                name="jobTitle"
                label="Job title"
                width="100%"
                mb="xsmall"
                maxLength={50}
            />
            <CompanySelect
                name="company"
                label="Company"
                mb="small"
                isSearchable
                shouldCloseMenuOnScroll
            />
            <JobTypeSelect
                name="jobTypes"
                label="Role type"
                placeholder="Search for roles"
                isMulti
                shouldCloseMenuOnScroll
                mb="small"
                width="100%"
            />
            <ResponsiveInputBox>
                <SingleSelect
                    name="workType"
                    label="Job type"
                    placeholder="Select"
                    options={getSelectOptions(data?.workTypes, "slug", "name")}
                    width={["100%", "50%"]}
                    shouldCloseMenuOnScroll
                    closeMenuOnSelect={true}
                />
                <MultiSelect
                    name="experiences"
                    label="Experience level"
                    placeholder="Select"
                    options={getSelectOptions(data?.experience, "slug", "name")}
                    width={["100%", "50%"]}
                    shouldCloseMenuOnScroll
                    closeMenuOnSelect={true}
                />
            </ResponsiveInputBox>
            <Divider my="medium" />
            <InputWithCheckboxContainer>
                <LocationSelect
                    name="locations"
                    label="Location"
                    placeholder="Search or select locations"
                    isMulti
                    width="100%"
                    isSearchable
                    shouldCloseMenuOnScroll
                    mb="xxsmall"
                />
                <CheckboxField
                    label="Open to remote candidates"
                    name="isOpenToRemote"
                />
            </InputWithCheckboxContainer>
            <Divider my="medium" />
            <ResponsiveInputBox>
                <SingleSelect
                    name="salaryCurrency"
                    label="Currency"
                    options={data?.supportedCurrencies || []}
                    shouldCloseMenuOnScroll
                    closeMenuOnSelect={true}
                    width={["100%", "50%"]}
                />
                <CurrencyInputField
                    name="salaryMin"
                    label="Minimum salary"
                    sideDescription="Optional"
                    width="100%"
                    placeholder="$100,000"
                    detectCurrencyFieldName="salaryCurrency"
                />
                <CurrencyInputField
                    name="salaryMax"
                    label="Maximum salary"
                    sideDescription="Optional"
                    width="100%"
                    placeholder="$100,000"
                    detectCurrencyFieldName="salaryCurrency"
                />
            </ResponsiveInputBox>
            <Box my="xsmall" />
            <ResponsiveInputBox>
                <CurrencyInputField
                    name="equityMin"
                    label="Minimum equity"
                    sideDescription="Optional"
                    width="100%"
                    placeholder="0.01%"
                    options={{
                        suffix: "%",
                    }}
                />
                <CurrencyInputField
                    name="equityMax"
                    label="Maximum equity"
                    sideDescription="Optional"
                    width="100%"
                    placeholder="1%"
                    options={{
                        suffix: "%",
                    }}
                />
            </ResponsiveInputBox>
            <Divider my="medium" />
            {atsEnabled && (!!team || !!user?.currentTeam?.uuid) && (
                <ATSJobSelect
                    team={team || user!.currentTeam!.uuid}
                    mb="medium"
                />
            )}
            <TextInputField
                name="link"
                label="Application link or email"
                description="Application link must be a valid ATS link or email address."
                placeholder="https://greenhouse.com/company/job-posting"
                width="100%"
                mb="medium"
            />
            <ui.Label
                label="Description"
                description="Tell candidates what this job is all about"
            />
            <RichTextInput
                name="richJobDescription"
                label=""
                title
                height="100%"
                width="100%"
                rows={100}
            />
        </Box>
    )
}

export default JobPostForm

const ResponsiveInputBox = styled(Box)`
    display: flex;
    ${mq({
        flexDirection: ["column", "row"],
    })};
    gap: ${theme.space.small};
`

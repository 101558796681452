import { gql, useMutation } from "@apollo/client"
import { Box, Button, Text, theme, UrlInputField } from "@pallet-hq/hegel"
import { FormikValues, useFormikContext } from "formik"
import { useState } from "react"
import CompanySelectImage from "components/inputs/Select/CompanySelectImage"
import {
    getSelectOptions,
    getSelectOptions2,
} from "components/inputs/Select/utils"
import BetaPill from "components/BetaPill"
import useAuthContext from "state/useAuthContext"
import { ImportJobMutation } from "__generated__/ImportJobMutation"
import { urlValidator } from "utils/sharedValidationSchemas"

const IMPORT_JOB_MUTATION = gql`
    mutation ImportJobMutation($url: String!, $recruitingTeam: String) {
        importJob(url: $url, recruitingTeam: $recruitingTeam) {
            scrapedJob {
                id
                title
                link
                description
                company {
                    id
                    slug
                    name
                    image
                }
                experienceLevels {
                    id
                    slug
                    name
                }
                predictedJobType {
                    edges {
                        node {
                            id
                            slug
                            name
                        }
                    }
                }
                convertedWorkType {
                    id
                    slug
                    name
                }
            }
        }
    }
`

const JobScraperInput = () => {
    const [importing, setImporting] = useState<boolean>(false)
    const [importEmpty, setImportEmpty] = useState<boolean>(false)

    const { user } = useAuthContext()
    const [importJob] = useMutation<ImportJobMutation>(IMPORT_JOB_MUTATION)
    const { setFieldValue, values } = useFormikContext<FormikValues>()

    const handleClick = async () => {
        setImporting(true)
        const { data } = await importJob({
            variables: {
                url: values.importJobUrl,
                recruitingTeam: user?.currentTeam?.uuid,
            },
        })
        setImporting(false)
        if (data?.importJob?.scrapedJob) {
            setImportEmpty(false)
            if (data.importJob.scrapedJob.title) {
                setFieldValue("jobTitle", data.importJob.scrapedJob.title)
            }
            if (data.importJob.scrapedJob.description) {
                setFieldValue(
                    "richJobDescription",
                    data.importJob.scrapedJob.description
                )
            }
            if (data.importJob.scrapedJob.link) {
                setFieldValue("link", data.importJob.scrapedJob.link)
            } else {
                setFieldValue("link", values.importJobUrl)
            }
            if (data.importJob.scrapedJob.experienceLevels) {
                setFieldValue(
                    "experiences",
                    getSelectOptions(
                        data.importJob.scrapedJob.experienceLevels,
                        "slug",
                        "name"
                    )
                )
            }
            if (data.importJob.scrapedJob.predictedJobType) {
                setFieldValue(
                    "jobTypes",
                    getSelectOptions2(
                        data.importJob.scrapedJob.predictedJobType.edges,
                        "slug",
                        "name"
                    )
                )
            }
            if (data.importJob.scrapedJob.convertedWorkType) {
                setFieldValue(
                    "workType",
                    getSelectOptions(
                        [data.importJob.scrapedJob.convertedWorkType],
                        "slug",
                        "name"
                    )[0]
                )
            }
            if (data.importJob.scrapedJob.company.id) {
                setFieldValue("company", {
                    value: data.importJob.scrapedJob.company.slug,
                    label: data.importJob.scrapedJob.company.name,
                    icon: (
                        <CompanySelectImage
                            node={{
                                image: data.importJob.scrapedJob.company.image,
                            }}
                        />
                    ),
                })
            }
        } else {
            setImportEmpty(true)
        }
    }

    return (
        <>
            {/*custom <ui.Label> for this input*/}
            <Box display="flex" flexDirection="column" mb="xxsmall">
                <Box display="flex" alignItems="center" gridGap="xsmall">
                    <Text variant="uiSmall500">Import a job post</Text>
                    <BetaPill />
                </Box>
                <Text variant="paragraphMedium" color="gray600" mb={1}>
                    Paste the link to an existing job post. Only links from
                    Greenhouse, Lever, and Workable can be scraped.
                </Text>
            </Box>
            <Box
                display="flex"
                alignItems="flex-start"
                style={{ gap: theme.space.xsmall }}
            >
                <UrlInputField
                    name="importJobUrl"
                    placeholder="https://lever.com/pallet/jobs/desig..."
                    width="100%"
                    type="url"
                    autoFocus
                />
                <Button
                    variant="secondary"
                    size="small"
                    onClick={handleClick}
                    loading={importing}
                    disabled={
                        !values.importJobUrl ||
                        !urlValidator().isValidSync(values.importJobUrl)
                    }
                >
                    Import job
                </Button>
            </Box>
            {importEmpty && (
                <Text variant="paragraphSmall" color="red700" mt="xxsmall">
                    Failed to find a job post at this link. Please try another
                    url or create your job post below.
                </Text>
            )}
        </>
    )
}

export default JobScraperInput

import { Box, Text, theme } from "@pallet-hq/hegel"

const SplitDivider = ({ label }: { label: string }) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="1px"
        background={theme.colors.gray300}
        my="medium"
    >
        <Text variant="uiSmall500" bg="white" px="xxsmall" color="gray700">
            {label}
        </Text>
    </Box>
)

export default SplitDivider

import { gql } from "@apollo/client"

export const EDIT_JOB_POST_FRAGMENT = gql`
    fragment EditJobPost_post on PostType {
        id
        uuid
        jobTitle
        company {
            id
            name
            slug
            image
        }
        jobTypes {
            edges {
                node {
                    id
                    name
                    slug
                }
            }
        }
        workType {
            id
            name
            slug
        }
        experienceLevels {
            id
            name
            slug
        }
        locations {
            id
            geonameId
            locationType
            shortLabel
            longLabel
        }
        isOpenToRemote
        salaryMin {
            amount
            currency
        }
        salaryMax {
            amount
            currency
        }
        equityMin
        equityMax
        link
        emailLink
        emailToApply
        description
        atsJob {
            id
            name
            modifiedAt
        }
        atsInterviewStage {
            id
            name
            order
        }
    }
`

export const EDIT_JOB_POST_LISTING_FRAGMENT = gql`
    fragment EditJobPostListing_listing on ListingType {
        id
        uuid
        promptAnswer
        statusEnum
    }
`

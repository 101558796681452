import { notify } from "@pallet-hq/hegel"
import { Formik, FormikValues } from "formik"
import { useRouter } from "next/router"
import { ReactNode } from "react"
import * as Yup from "yup"
import useAuthContext from "state/useAuthContext"
import useJobPostEditorModal from "state/useJobPostEditorModal"
import CompanySelectImage from "components/inputs/Select/CompanySelectImage"
import { getOptions } from "components/inputs/Select/LocationSelect"
import {
    convertOptionsToValues,
    convertOptionToValue,
    getSelectOptions,
    getSelectOptions2,
} from "components/inputs/Select/utils"
import { reqMaxCharValidator } from "utils/validators"
import { useApplylistQuery } from "features/job-posts/editor/hooks/useApplylistQuery"
import { useMetadataQuery } from "features/job-posts/editor/hooks/useMetadataQuery"
import { usePostQuery } from "features/job-posts/editor/hooks/usePostQuery"
import { useCreateOrUpdatePostMutation } from "features/job-posts/editor/hooks/useCreateOrUpdatePostMutation"
import { BaseJobPostProps } from "features/job-posts/editor/shared"

type PostFormProviderProps = {
    children: ReactNode
    afterSubmit: (postUuid: string, postTitle?: string) => void
} & BaseJobPostProps

const PostFormProvider = (props: PostFormProviderProps) => {
    const { user } = useAuthContext()
    const router = useRouter()
    const { team } = useJobPostEditorModal()
    const [updatePost] = useCreateOrUpdatePostMutation(team)
    const postQuery = usePostQuery(props.postUuid)
    const metadataQuery = useMetadataQuery()
    const applylistQuery = useApplylistQuery()

    if (postQuery.loading || !metadataQuery.data || applylistQuery.loading)
        return null

    const canEditAppylist = applylistQuery.data?.applylist?.canEdit
    const isUpdating = postQuery.data?.post?.uuid

    const assembleInitialValues = () => {
        const post = postQuery.data?.post

        const initialJobTypes = post?.jobTypes
            ? getSelectOptions2(post.jobTypes.edges, "slug", "name")
            : []
        const initialLocations = post?.locations
            ? getOptions(post.locations)
            : []
        const initialExperiences = post?.experienceLevels
            ? getSelectOptions(post.experienceLevels, "slug", "name")
            : []

        let initialSalaryCurrency =
            metadataQuery.data?.supportedCurrencies?.filter(
                currency =>
                    currency.label === post?.salaryMin?.currency ?? "USD"
            )[0] || { value: "usd", label: "USD" }

        const company = post?.company ?? user?.currentTeam?.company
        let initialCompany = company
            ? {
                  value: company.slug,
                  label: company.name,
                  icon: <CompanySelectImage node={{ image: company.image }} />,
              }
            : undefined

        return {
            jobTitle: post?.jobTitle || "",
            company: initialCompany,
            jobTypes: initialJobTypes,
            workType: post?.workType
                ? getSelectOptions([post.workType], "slug", "name")[0]
                : undefined,
            locations: initialLocations,
            isOpenToRemote: post?.isOpenToRemote || false,
            salaryCurrency: initialSalaryCurrency,
            salaryMin: post?.salaryMin?.amount,
            salaryMax: post?.salaryMax?.amount,
            equityMin: post?.equityMin,
            equityMax: post?.equityMax,
            link: post?.emailToApply ? post?.emailLink : post?.link || "",
            experiences: initialExperiences,
            jobDescription: "",
            richJobDescription: post?.description || "",
            atsJob: post?.atsJob
                ? getSelectOptions([post.atsJob], "id", "name")[0]
                : undefined,
            atsInterviewStage: post?.atsInterviewStage
                ? getSelectOptions([post.atsInterviewStage], "id", "name")[0]
                : undefined,
        }
    }

    const baseValidationSchema = {
        jobTitle: reqMaxCharValidator(50),
        company: Yup.object().required("Required"),
        jobTypes: Yup.array()
            .min(1, "Please select at least 1 role")
            .max(3, "Please select no more than 3 roles"),
        workType: Yup.object().required("Required"),
        experiences: Yup.array()
            .min(1, "Please select at least 1 experience level")
            .max(3, "Please select no more than 3 experience levels"),
        locations: Yup.array()
            .max(3, "Please select no more than 3 locations")
            .when("isOpenToRemote", {
                is: false,
                then: Yup.array().min(1, "Please select at least 1 location"),
            }),
        salaryCurrency: Yup.object().required("Required"),
        salaryMin: Yup.number()
            .nullable()
            .typeError("Must be a number")
            .positive("Must be positive")
            .integer("Must be a whole number"),
        salaryMax: Yup.number()
            .nullable()
            .typeError("Must be a number")
            .integer("Must be a whole number")
            .moreThan(
                Yup.ref("salaryMin"),
                "Must be greater than minimum salary"
            ),
        equityMin: Yup.number()
            .nullable()
            .typeError("Must be a number")
            .min(0, "Must be over 0")
            .max(100, "Must be under 100"),
        equityMax: Yup.number()
            .nullable()
            .typeError("Must be a number")
            .max(100, "Must be no more than 100")
            .min(Yup.ref("equityMin"), "Must be greater than minimum equity"),
        link: Yup.lazy(value =>
            !value
                ? Yup.string().required("Required")
                : value.includes("@")
                ? Yup.string()
                      .email(
                          "Please enter a valid email or a link starting with https://"
                      )
                      .max(400, "Must be under 400 characters")
                : Yup.string()
                      .url(
                          "Please enter a valid email or a link starting with https://"
                      )
                      .max(400, "Must be under 400 characters")
        ),
        richJobDescription: Yup.string().required("Required"),
    }

    const unpackValuesForSubmit = (values: FormikValues) => {
        return {
            postUuid: postQuery.data?.post?.uuid,
            jobTitle: values.jobTitle,
            company: convertOptionToValue(values.company),
            jobTypes: convertOptionsToValues(values.jobTypes),
            workType: convertOptionToValue(values.workType),
            experiences: convertOptionsToValues(values.experiences),
            locations: convertOptionsToValues(values.locations),
            isOpenToRemote: values.isOpenToRemote,
            salaryCurrency: convertOptionToValue(values.salaryCurrency),
            salaryMin: values.salaryMin ? parseInt(values.salaryMin) : null,
            salaryMax: values.salaryMax ? parseInt(values.salaryMax) : null,
            equityMin: values.equityMin ? parseFloat(values.equityMin) : null,
            equityMax: values.equityMax ? parseFloat(values.equityMax) : null,
            link: values.link,
            jobDescription: values.jobDescription,
            richJobDescription: values.richJobDescription,
            atsJobId: values.atsJob
                ? convertOptionToValue(values.atsJob)
                : null,
            atsInterviewStageId: values.atsInterviewStage
                ? convertOptionToValue(values.atsInterviewStage)
                : null,
        }
    }

    return (
        <Formik
            initialValues={assembleInitialValues()}
            validationSchema={Yup.object().shape(baseValidationSchema)}
            onSubmit={async values => {
                const { data, errors } = await updatePost({
                    variables: unpackValuesForSubmit(values),
                })
                const action = isUpdating ? "update" : "create a new"
                if (!data || data.postCreateOrUpdate?.userError || errors) {
                    if (errors) {
                        console.error(errors)
                    } else if (data?.postCreateOrUpdate?.userError) {
                        console.error(data.postCreateOrUpdate.userError.message)
                    }
                    notify.fail(
                        `Failed to ${action} job post. Please try again.`
                    )
                } else {
                    const action = isUpdating ? "updated" : "created"
                    notify.success(`Successfully ${action} job post.`, {
                        action: canEditAppylist ? undefined : "Track",
                        onAction: () =>
                            router.push(
                                `/team/${user?.currentTeam?.uuid}/jobs`
                            ),
                    })
                    const post = data.postCreateOrUpdate?.post
                    return (
                        !!props.afterSubmit &&
                        props.afterSubmit(post?.uuid, post?.jobTitle)
                    )
                }
            }}
            validateOnMount
            {...props}
        />
    )
}

export default PostFormProvider

import { gql, useMutation } from "@apollo/client"
import useAuthContext from "state/useAuthContext"
import { EDIT_JOB_POST_FRAGMENT } from "../fragments"
import { JobPostCreateOrUpdateMutation } from "__generated__/JobPostCreateOrUpdateMutation"

const JOB_POST_CREATE_OR_UPDATE_MUTATION = gql`
    mutation JobPostCreateOrUpdateMutation(
        $recruitingTeamUuid: String
        $postUuid: String
        $jobTitle: String
        $company: String
        $link: String
        $jobTypes: [String!]
        $jobTypeCategories: [String!]
        $workType: String
        $experiences: [String!]
        $industries: [String!]
        $skills: [String!]
        $locations: [LocationInputType!]
        $isOpenToRemote: Boolean
        $jobDescription: String
        $richJobDescription: String
        $salaryCurrency: String
        $salaryMin: Int
        $salaryMax: Int
        $equityMin: Float
        $equityMax: Float
        $atsJobId: String
        $atsInterviewStageId: String
    ) {
        postCreateOrUpdate(
            recruitingTeamUuid: $recruitingTeamUuid
            postUuid: $postUuid
            jobTitle: $jobTitle
            company: $company
            link: $link
            jobTypes: $jobTypes
            jobTypeCategories: $jobTypeCategories
            workType: $workType
            experiences: $experiences
            industries: $industries
            skills: $skills
            locations: $locations
            isOpenToRemote: $isOpenToRemote
            jobDescription: $jobDescription
            richJobDescription: $richJobDescription
            salaryCurrency: $salaryCurrency
            salaryMin: $salaryMin
            salaryMax: $salaryMax
            equityMin: $equityMin
            equityMax: $equityMax
            atsJobId: $atsJobId
            atsInterviewStageId: $atsInterviewStageId
        ) {
            post {
                id
                ...EditJobPost_post
            }
            userError {
                message
            }
        }
    }
    ${EDIT_JOB_POST_FRAGMENT}
`

export const useCreateOrUpdatePostMutation = (recruitingTeamUuid?: string) => {
    const { user } = useAuthContext()
    return useMutation<JobPostCreateOrUpdateMutation>(
        JOB_POST_CREATE_OR_UPDATE_MUTATION,
        {
            variables: {
                recruitingTeamUuid:
                    recruitingTeamUuid || user?.currentTeam?.uuid,
            },
        }
    )
}

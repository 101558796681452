import { gql, useQuery } from "@apollo/client"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { GetApplylistListingQuery } from "__generated__/GetApplylistListingQuery"

export const GET_APPLYLIST_LISTING_QUERY = gql`
    query GetApplylistListingQuery($slug: String!) {
        applylist(slug: $slug) {
            ... on ApplylistType {
                id
                canEdit
                posterPrompt
                posterPromptRequired
                curatorName
                curatorAvatar {
                    source
                }
                primaryColor
            }
        }
    }
`

export const useApplylistQuery = (palletSlug?: string) => {
    const { pallet } = usePalletContext()
    const slug = palletSlug || pallet
    return useQuery<GetApplylistListingQuery>(GET_APPLYLIST_LISTING_QUERY, {
        variables: { slug },
        skip: !slug,
    })
}

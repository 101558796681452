import React from "react"
import { Pill, PillProps, Text, TextVariant } from "@pallet-hq/hegel"

type BetaPillProps = { textVariant?: TextVariant } & Omit<PillProps, "children">

const BetaPill = (props: BetaPillProps) => {
    const { textVariant } = props
    return (
        <Pill
            py="xxsmall"
            px="xsmall"
            backgroundColor="orange100"
            border="none"
            {...props}
        >
            <Text variant={textVariant || "uiTiny500"}>Beta</Text>
        </Pill>
    )
}

export default BetaPill

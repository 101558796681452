import { useQuery } from "@apollo/client"
import styled from "@emotion/styled"
import {
    BaseCheckboxField,
    Box,
    noop,
    SingleSelect,
    SingleSelectProps,
    Text,
    theme,
    ui,
} from "@pallet-hq/hegel"
import { FormikValues, useField, useFormikContext } from "formik"
import moment from "moment"
import { getSelectOptions, SelectOptions } from "components/inputs/Select/utils"
import { TEAM_INTEGRATION_JOBS_QUERY } from "queries"
import { TeamIntegrationJobsQuery } from "__generated__/TeamIntegrationJobsQuery"

type ATSJobSelectProps = {
    team: string
} & Omit<SingleSelectProps, "name" | "options">

export const ATSJobSelect = (props: ATSJobSelectProps) => {
    const { initialValues, values } = useFormikContext<FormikValues>()
    const [, jobMeta, jobHelpers] = useField("atsJob")
    const [, , stageHelpers] = useField("atsInterviewStage")
    const { data } = useQuery<TeamIntegrationJobsQuery>(
        TEAM_INTEGRATION_JOBS_QUERY,
        { variables: { team: props.team } }
    )
    const jobs = data?.recruitingTeam.integration?.jobs

    const getJobOptions = (): SelectOptions => {
        // Sort by moving initially selected job to front and then by modifiedAt
        const sortedJobs = jobs
            ?.slice()
            .sort(
                (
                    s1: { id: string; modifiedAt: any },
                    s2: { id: string; modifiedAt: any }
                ) =>
                    s1.id == initialValues.atsJob?.value
                        ? -1
                        : s2.id == initialValues.atsJob?.value
                        ? 1
                        : s1.modifiedAt - s2.modifiedAt
            )
        const jobsWithDescription = sortedJobs?.map(job => ({
            id: job.id,
            name: job.name,
            description: `Last updated ${moment(job.modifiedAt).format("LL")}`,
        }))
        return getSelectOptions(
            jobsWithDescription,
            "id",
            "name",
            undefined,
            undefined,
            ["description"]
        )
    }

    const hasStages = (): boolean => {
        if (!jobs || !values.atsJob) return false
        return !!jobs.find(job => job.id === values.atsJob.value)?.stages
            ?.length
    }

    const getInterviewOptions = (): SelectOptions => {
        if (!jobs || !values.atsJob) return []
        const stages = jobs.find(job => job.id === values.atsJob.value)?.stages
        if (!stages) return []
        const sortedStages = stages
            .slice()
            .sort(
                (s1: { order: number }, s2: { order: number }) =>
                    s1.order - s2.order
            )
        return getSelectOptions(sortedStages, "id", "name")
    }

    if (!data?.recruitingTeam.integration?.isActive) return null

    return (
        <Box display="flex" flexDirection="column">
            <ui.Label
                label="Select a job"
                description={
                    data.recruitingTeam.integration.name
                        ? `Select a job from ${data.recruitingTeam.integration.name}`
                        : ""
                }
            />
            <ScrollBox>
                {getJobOptions().map(option => {
                    const selected = values.atsJob?.value == option.value
                    return (
                        <JobSelectBox
                            key={`JobSelectBox_${option.value}`}
                            selected={selected}
                            onClick={() => {
                                jobHelpers.setValue(option)
                                stageHelpers.setValue(null)
                            }}
                        >
                            <Box display="flex" flexDirection="column">
                                <Text variant="paragraphLarge">
                                    {option.label}
                                </Text>
                                {
                                    // @ts-ignore other is set on Option by getSelectOptions
                                    option.other?.description && (
                                        <Text
                                            variant="uiSmall500"
                                            color="gray600"
                                        >
                                            {
                                                // @ts-ignore other is set on Option by getSelectOptions
                                                option.other.description
                                            }
                                        </Text>
                                    )
                                }
                            </Box>
                            <BaseCheckboxField
                                label=""
                                name=""
                                isSelected={selected}
                                onChange={noop}
                            />
                        </JobSelectBox>
                    )
                })}
            </ScrollBox>
            <ui.ErrorMessage
                showError={!!(jobMeta.touched && jobMeta.error)}
                message={jobMeta.error}
            />
            {hasStages() && (
                <Box display={["column", "flex"]} alignItems="center">
                    <Box mb={["0px", "small"]} mr="xsmall">
                        <ui.Label label="Interview stage" />
                    </Box>
                    <SingleSelect
                        name="atsInterviewStage"
                        label=""
                        width={["100%", "340px"]}
                        options={getInterviewOptions()}
                        isSearchable
                        closeMenuOnSelect
                        {...props}
                    />
                </Box>
            )}
        </Box>
    )
}

const ScrollBox = styled(Box)`
    display: flex;
    grid-gap: ${theme.space.xsmall};
    overflow-x: auto;
    box-sizing: border-box;
    padding: 0px 2px 16px 2px;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`

const JobSelectBox = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 252px;
    border-radius: ${theme.space.xsmall};
    padding: 12px 0 12px 12px;
    cursor: pointer;
    ${({ selected }: { selected: boolean }) =>
        selected
            ? `border: 2px solid ${theme.colors.gold400};`
            : `border: 1px solid ${theme.colors.gray300};`}
    box-shadow:
        0px 0px 0px 0px rgba(0, 0, 0, 0.06),
        0px 1px 2px 0px rgba(0, 0, 0, 0.06),
        0px 3px 3px 0px rgba(0, 0, 0, 0.05),
        0px 6px 4px 0px rgba(0, 0, 0, 0.03),
        0px 11px 4px 0px rgba(0, 0, 0, 0.01),
        0px 17px 5px 0px rgba(0, 0, 0, 0.00);
`
